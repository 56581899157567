enum WebViewMessage {
  WEBVIEW_CLOSE = 'webview-close',
  API_TOKEN = 'api_token',
  ADDRESS_MODAL_CLOSE = 'address-modal-close',
  ADDRESS_MODAL_CHANGE = 'address-modal-change',
  ACCOUNT_DELETE_REQUESTED = 'account-delete-requested',
  PAYMENT_INFO_REQUESTED = 'payment-info-requested',
  SHIPPING_ADDRESS_REQUESTED = 'shipping-address-requested',
  SUBSCRIPTION_PAUSED = 'subscription_paused',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  NAVIGATE_TO_MENUS = 'navigate-to-menus',
  GOOGLE_SIGNUP_SUCCESS = 'google_oauth2-signup-success',
  APPLE_SIGNUP_SUCCESS = 'apple-signup-success',
  FACEBOOK_SIGNUP_SUCCESS = 'facebook-signup-success',

}

export default WebViewMessage;
